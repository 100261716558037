<template>
  <v-container
    v-if="$route.params.hash"
    fluid
    class="pt-15"
    max-width="100%"
  >
    <v-app-bar
      fixed
      dense
      scroll-off-screen
      elevation="2"
    >
      <h4 v-if="provider">
        {{ provider.first_name }}
        {{ provider.last_name }}
      </h4>
      <v-img
        v-else
        src="/img/precision-asp.svg"
        :contain="true"
        :max-width="190"
        :max-height="42"
      />
      <v-spacer />
      <v-img
        v-if="client"
        :src="client.logo"
        :contain="true"
        :height="32"
        position="left"
      />
      <v-menu
        ottom
        cright
        offset-y
      >
        <template #activator="{ on }">
          <v-img
            src="/img/arkstone-logo-icon.svg"
            :contain="true"
            :max-width="42"
            :height="42"
            position="right"
            v-on="on"
          />
        </template>
        <v-list>
          <v-dialog
            v-model="dialog"
            max-width="500"
          >
            <template #activator="{ on }">
              <v-list-item v-on="on">
                <v-list-item-action class="mr-4">
                  <v-icon small>
                    fal fa-circle-info
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content><v-list-item-title>About Precision ASP</v-list-item-title></v-list-item-content>
              </v-list-item>
            </template>
            <v-card>
              <v-card-title>
                <v-img
                  src="/img/precision-asp.svg"
                  :contain="true"
                  width="65%"
                  :max-width="200"
                />
                <v-spacer />
                <v-btn
                  icon
                  tile
                  right
                  color="grey"
                  @click.stop="dialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                Arkstone's Precision ASP is designed to assist providers and improve the treatment of infections.
                Recommendations are for education purposes only.
                Clinical correlation and physician judgement required when making a diagnosis or treatment decisions.
                Patients have not been examined, nor their medical history reviewed.
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-divider />
          <v-list-item>
            <v-list-item-action class="mr-4">
              <v-icon small>
                fal fa-question-square
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <a href="mailto:support@arkstonemedical.com">support@arkstonemedical.com</a>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <template
      v-if="!provider"
    >
      <v-card flat>
        <v-form @submit.prevent="fetchPatients">
          <v-card-text>
            <v-text-field
              v-model="last_name"
              label="Please confirm your last name"
              outlined
              :error="error"
              :error-messages="error_message"
            />
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="12">
                <v-btn
                  color="blue darken-3"
                  ripple
                  block
                  dark
                  large
                  type="submit"
                  :loading="loading"
                >
                  {{ $t('Submit') }}
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-alert
                  v-if="error_count > 1"
                  transition="scale-transition"
                  type="error"
                >
                  Trouble signing in? Let us know at <a href="mailto:support@arkstonemedical.com">support@arkstonemedical.com</a>
                </v-alert>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-card>
    </template>
    <template
      v-else-if="Object.keys(patients).length"
    >
      <v-row
        v-for="patient in patients"
        :key="patient.uuid"
      >
        <v-col>
          <v-card
            elevation="1"
          >
            <v-card-title
              class="grey lighten-4"
            >
              {{ patient.last_name }}, {{ patient.first_name }}
              <v-spacer />
              <v-menu
                xbottom
                xright
                offset-y
              >
                <template #activator="{ on }">
                  <v-btn
                    small
                    ripple
                    icon
                    plain
                    v-on="on"
                  >
                    <v-icon>fal fa-ellipsis-v</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <not-my-patient
                    :hash="$route.params.hash"
                    :patient="patient"
                    :facility="patient.prescriptions[0].facility"
                  />
                </v-list>
              </v-menu>
            </v-card-title>
            <v-card-subtitle
              class="grey lighten-4"
            >
              DOB {{ patient.dob | moment('L') }}
            </v-card-subtitle>
            <v-list
              class="py-0"
            >
              <template
                v-for="prescription in patient.prescriptions.filter(x => x.flags.length > 0)"
              >
                <v-divider
                  :key="'divider-' + prescription.uuid"
                />
                <v-list-group
                  :key="'prescription-' + prescription.uuid"
                >
                  <template #activator>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ prescription.drug.name.en }}
                        {{ prescription.dosage }}
                        {{ prescription.route }}
                        {{ prescription.interval }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="mt-1 mb-2">
                        <v-icon
                          class="mt-n1"
                          small
                        >
                          far fa-calendar-week
                        </v-icon>
                        {{ prescription.start_date | moment('L') }} -
                        <template
                          v-if="prescription.end_date"
                        >
                          {{ prescription.end_date | moment('L') }}
                        </template>
                        <template v-else>
                          ?
                        </template>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <warning-chip
                          v-for="flag in prescription.flags"
                          :key="'asp_chip_' + flag"
                          :flag="flag"
                          x-small
                          class="mr-1"
                        />
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                  <template #xappendIcon>
                    <arkscore-gauge
                      :key="'arkscore-gauge-' + prescription.drug.score_adverse_reaction"
                      :value="prescription.drug.score_adverse_reaction"
                      :size="30"
                      right
                    />
                  </template>
                  <v-list-item
                    v-for="(flag, index) in prescription.flags"
                    :key="index"
                    two-line
                  >
                    <v-list-item-action
                      class="mr-3"
                    >
                      <warning-chip
                        :key="'asp_dot_' + flag"
                        :flag="flag"
                        small
                        dot
                      />
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-subtitle
                        class="text-wrap"
                        v-text="message(flag, prescription)"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <arkscore-card
                    :key="'arkscore-card-' + prescription.drug.score_adverse_reaction"
                    :name="$t('arkscore.adverse_reaction')"
                    :score="prescription.drug.score_adverse_reaction"
                    blurb="The likelihood of the drug to cause adverse effects to the patient."
                    :bar-height="12"
                    flat
                  />
                </v-list-group>
              </template>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <template
      v-else
    >
      Good news. None of your current prescriptions have been flagged by Precision ASP.
    </template>
  </v-container>
</template>

<script>
import Vue2Filters from "vue2-filters";

export default {
  name: "PrecisionASP",
  metaInfo() {
    return {
      title: 'PrecisionASP',
    };
  },
  components: {
    WarningChip: () => import('@/components/asp/WarningChip.vue'),
    ArkscoreGauge: () => import('@/components/arkscore/ArkscoreGauge.vue'),
    ArkscoreCard: () => import('@/components/arkscore/ArkscoreCard.vue'),
    NotMyPatient: () => import('@/components/asp/NotMyPatient.vue'),
    // pdf: () => import('vue-pdf'),
  },
  mixins: [Vue2Filters.mixin],
  data: () => ({
    icon: "/img/arkstone-logo-icon.svg",
    loading: false,
    valid: true,
    client: null,
    provider: null,
    patients: {},
    translations: {},
    last_name: null,
    error: false,
    error_message: null,
    error_count: 0,
    dialog: false,
  }),
  computed: {
  },
  watch: {},
  // created() {
  //   this.fetchPatients()
  // },
  mounted() {},
  methods: {
    fetchPatients () {
      this.loading = true
      const promise = this.axios.get('asp/' + this.$route.params.hash + '/patients', {
        params: {
          last_name: this.last_name,
        },
      })

      return promise.then((response) => {
        this.provider = response.data.provider
        this.patients = response.data.patients
      })
        .catch(error => {
          if (error.response.status == 404 || error.response.status == 422) {
            this.error_count++
            this.error = true
            this.error_message = 'The information you entered is incorrect. Try again.'
          } else if (error.response.data?.message) {
            this.$toast.error(error.response.data.message)
          } else {
            this.$toast.error(error)
          }
        })
        .finally(() => { this.loading = false })
    },
    message (flag, prescription) {
      var message = ''
      switch (flag) {
        case 'no_end_date':
          message = 'No end date has been specified for this prescription. Consider antibiotic timeout and re-evaluation of antibiotic necessity.'
          break
        case 'over_3_days':
          var passed = this.$moment().diff(prescription.start_date, 'days') + 1
          if (passed) {
            message = 'Today is day ' + passed + ' of '
          } else {
            message = 'This is '
          }
          message += 'a ' + (this.$moment(prescription.end_date).diff(prescription.start_date, 'days') + 1) + ' day prescription. Consider antibiotic timeout and re-evaluation of antibiotic necessity.'
          break
        case 'broad_spectrum':
          message = 'As a broad spectrum antibiotic, ' + prescription.drug.name.en + ' is typically used empirically. If possible, de-escalation to a more narrow spectrum antimicrobial is recommended.'
          break
        case 'risk_c_diff':
          message = prescription.drug.name.en + ' may be associated with increased risks for the acquisition of C. diff. If possible, consider antibiotic de-escalation, or discontinuation.'
          break
        case 'second_line':
          message = prescription.drug.name.en + ' is typically considered in specific circumstances and not typically used for common infections'
          break
        case 'lab_monitoring':
          message = prescription.drug.name.en + ' may require lab monitoring to assure adequate drug concentrations or levels. In addition, lab monitoring may be needed to assure there is no risk to the patient.'
          if (prescription.drug.renal_dosing || prescription.drug.hepatic_dosing) {
            var adjustment = []
            message += ' Also, '
            if (prescription.drug.renal_dosing) {
              adjustment.push(' renal ')
            }
            if (prescription.drug.hepatic_dosing) {
              adjustment.push(' hepatic ')
            }
            message += adjustment.join(' and/or ') + ' dosing adjustments may be necessary.'
          }
          break
        case 'caution':
          message = prescription.drug.name.en + ' may be associated with adverse drug reactions to the patient. If possible, consider an alternative treatment regimen.'
          break
        case 'fda_warnings':
          message = 'The FDA has issued warnings for ' + prescription.drug.name.en + '. Therefore, consider an alternative antimicrobial if possible, to reduce possible risk of adverse reactions.'
          break
        default:
          message = flag
      }
      return message
    },
    track () {
      this.$gtag.set({
        user_id: this.provider.uuid,
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.branded {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1em;
}
.inner-text {
  height: 100%;
  width: 100%;
  font-size: 4.5em;
  padding-top: 0.9em;

  span {
    max-width: 100px;
  }
}
</style>
<style>
.arkscore-pill {
  display: inline-block;
  height: 14px;
  vertical-align: middle;
  margin-right: 2px;
}
</style>
